import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
	Avatar,
	Box,
	Button,
	Checkbox,
	CssBaseline,
	FormControl,
	FormControlLabel,
	Grid,
	IconButton,
	InputAdornment,
	InputLabel,
	Link,
	OutlinedInput,
	Paper,
	TextField,
	Typography,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import { makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';

import Copyright from '../components/Copyright';
import { authenticate } from '../redux/authSlice';

const useStyles = makeStyles((theme) => ({
	root: {
		height: '100vh',
	},
	image: {
		backgroundImage: 'url("/announcment_photo.jpeg")',
		backgroundRepeat: 'no-repeat',
		backgroundColor:
			theme.palette.type === 'light'
				? theme.palette.grey[50]
				: theme.palette.grey[900],
		backgroundSize: 'cover',
		backgroundPosition: 'center',
	},
	paper: {
		margin: theme.spacing(8, 4),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	errorText: {
		color: 'red',
	},
}));

const Login = () => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const [error, setError] = useState(null);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [isRemembered, setIsRemembered] = useState(false);
	const [showPassword, setShowPassword] = useState(false);

	const submitHandler = async (e) => {
		e.preventDefault();
		setError(null);
		const values = {
			email,
			password,
			isRemembered,
		};
		try {
			await dispatch(authenticate(values, true));
		} catch (error) {
			setError(error.message);
		}
	};

	return (
		<Grid container component='main' className={classes.root}>
			<CssBaseline />
			<Grid item xs={false} sm={4} md={7} className={classes.image} />
			<Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
				<div className={classes.paper}>
					<Avatar className={classes.avatar}>
						<LockOutlinedIcon />
					</Avatar>
					<Typography component='h1' variant='h5'>
						Sign in
					</Typography>
					<form className={classes.form} onSubmit={submitHandler}>
						<TextField
							variant='outlined'
							margin='normal'
							required
							fullWidth
							value={email}
							id='email'
							label='Email Address'
							autoComplete='email'
							autoFocus
							onChange={(e) => setEmail(e.target.value)}
						/>
						<FormControl variant='outlined' required fullWidth>
							<InputLabel htmlFor='password'>Password</InputLabel>
							<OutlinedInput
								type={showPassword ? 'text' : 'password'}
								id='password'
								value={password}
								onChange={(e) => setPassword(e.target.value)}
								endAdornment={
									<InputAdornment position='end'>
										<IconButton
											aria-label='toggle password visibility'
											onClick={() => setShowPassword(!showPassword)}
											edge='end'
										>
											{showPassword ? <Visibility /> : <VisibilityOff />}
										</IconButton>
									</InputAdornment>
								}
								labelWidth={70}
							/>
							{error && <div className={classes.errorText}>{error}</div>}
						</FormControl>
						<FormControlLabel
							control={
								<Checkbox
									color='primary'
									onClick={() => setIsRemembered(!isRemembered)}
								/>
							}
							label='Remember me'
						/>
						<Button
							type='submit'
							fullWidth
							variant='contained'
							color='primary'
							className={classes.submit}
						>
							Sign In
						</Button>
						<Grid container>
							<Grid item xs>
								<Link to='/' variant='body2' component={RouterLink}>
									Back
								</Link>
							</Grid>
							<Grid item>
								<Link to='/signup' variant='body2' component={RouterLink}>
									Don't have an account? Sign Up
								</Link>
							</Grid>
						</Grid>
						<Box mt={5}>
							<Copyright />
						</Box>
					</form>
				</div>
			</Grid>
		</Grid>
	);
};

export default Login;
