import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Container, Grid, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundImage: 'url("/flower_bg.jpeg")',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
}));

const LandingPage = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container component='main' maxWidth='sm'>
        <Typography component='h1' variant='h2' align='center' gutterBottom>
          Welcome Baby Girl Copas
        </Typography>
        <Typography align='center' gutterBottom component='h2' variant='h5'>
          Arrived: December 28th, 2021
        </Typography>
        <Typography variant='h5' align='center' color='textSecondary' paragraph>
          Thank you so much for supporting us and our little love. We are over
          the moon excited to embark on this next adventure!
        </Typography>
        <div className={classes.heroButtons}>
          <Grid container spacing={2} justifyContent='center'>
            <Grid item>
              <Button
                component={Link}
                variant='outlined'
                color='primary'
                to='/signup'
              >
                Create an Account
              </Button>
            </Grid>
            <Grid item>
              <Button
                component={Link}
                variant='contained'
                color='secondary'
                to='/login'
              >
                Login
              </Button>
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  );
};

export default LandingPage;
