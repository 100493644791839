import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { QueryClient, QueryClientProvider } from 'react-query';

import './index.css';
import App from './App';

import store from './redux/store';

const queryClient = new QueryClient();

const satisfy = {
	fontFamily: 'Satisfy',
	fontStyle: 'cursive',
	fontDisplay: 'swap',
	fontWeight: 400,
};

const theme = createTheme({
	palette: {
		primary: {
			main: '#a04c5d',
			contrastText: '#fff',
		},
		secondary: {
			main: '#d6a39d',
			contrastText: '#fff',
		},
		text: {
			primary: '#a04c5d',
			secondary: '#8c6c61',
		},
	},
	typography: {
		fontFamily: 'Satisfy',
	},
	overrides: {
		MuiBottomNavigation: {
			root: {
				backgroundColor: '#a04c5d',
				bottom: 0,
				position: 'fixed',
				width: '100%',
			},
		},
		MuiBottomNavigationAction: {
			root: {
				color: '#fff',
				'&$selected': {
					color: '#fff',
				},
			},
		},
		MuiCssBaseline: {
			'@global': {
				'@font-face': [satisfy],
			},
		},
	},
});

ReactDOM.render(
	<ReduxProvider store={store}>
		<QueryClientProvider client={queryClient}>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<App />
			</ThemeProvider>
		</QueryClientProvider>
	</ReduxProvider>,
	document.getElementById('root')
);
