import React from 'react';
import axios from 'axios';
import { useQuery } from 'react-query';
import { Container, Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { ItemContainer } from '../components/ItemContainer';
import { SkeletonCard } from '../components/SkeletonCard';

const useStyles = makeStyles((theme) => ({
	error: {
		textAlign: 'center',
	},
	cardGrid: {
		paddingTop: theme.spacing(8),
		paddingBottom: theme.spacing(8),
	},
	heroContent: {
		padding: theme.spacing(8, 0, 6),
		backgroundImage: 'url("/flower_bg.jpeg")',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
	},
}));

const Registry = () => {
	const styles = useStyles();

	const { isLoading, error, data } = useQuery('registry', async () => {
		const res = await axios.get('https://api.coracopas.com/registry');
		return res.data.sort((a, b) => a.price - b.price);
	});

	if (error) {
		return (
			<h1 className={styles.error}>
				Call Andrew and let him know, because he messed up!
			</h1>
		);
	}

	return (
		<>
			<Paper className={styles.heroContent} id='/registry' elevation={3}>
				<Container component='main' maxWidth='sm'>
					<Typography component='h1' variant='h2' align='center' gutterBottom>
						Cora's Registry
					</Typography>
					<Typography align='center' gutterBottom component='h2' variant='h5'>
						Please ship all large items directly to:
					</Typography>
					<Typography variant='h5' align='center' color='textSecondary'>
						The Copas Family
					</Typography>
					<Typography variant='h5' align='center' color='textSecondary'>
						6043 S Zante Way
					</Typography>
					<Typography
						variant='h5'
						align='center'
						color='textSecondary'
						gutterBottom
					>
						Aurora, CO 80015
					</Typography>
				</Container>
			</Paper>
			<Container className={styles.cardGrid} maxWidth='lg' id='Registry'>
				<Grid container spacing={4}>
					{isLoading ? (
						<>
							{[...Array(8)].map((e, i) => (
								<SkeletonCard />
							))}
						</>
					) : (
						data.map((d) => <ItemContainer key={d.itemId} item={d} />)
					)}
				</Grid>
			</Container>
		</>
	);
};

export default Registry;
