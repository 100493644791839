import React, { useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  CircularProgress,
  Grid,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation, useQueryClient } from 'react-query';

const useStyles = makeStyles(() => ({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
}));

export const ItemContainer = ({ item }) => {
  const userId = useSelector((state) => state.auth.userId);

  const styles = useStyles();
  const queryClient = useQueryClient();
  const [anchorEl, setAnchorEl] = useState(null);

  const ITEM_HEIGHT = 48;
  let needed = item.quantity - item.purchased;

  const mutation = useMutation(
    async (updateItem) => {
      await axios.post('https://api.coracopas.com/purchased', updateItem);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('registry');
      },
    }
  );

  const handleClick = (quan) => {
    const orderData = {
      itemId: item.itemId,
      purchased: item.purchased + quan,
      userId,
    };
    mutation.mutate(orderData);
    if (anchorEl !== null) {
      handleMenuClose();
    }
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  let menuItems = [
    <MenuItem disabled key='0'>
      Quantity
    </MenuItem>,
  ];
  for (let i = 1; i <= needed; i++) {
    menuItems.push(
      <MenuItem value={i} onClick={(e) => handleClick(e.target.value)} key={i}>
        {i}
      </MenuItem>
    );
  }

  return (
    <Grid item xs={12} sm={6} md={3}>
      <Card className={styles.card} raised>
        <CardMedia
          className={styles.cardMedia}
          image={item.imageURL}
          title={item.name}
        />
        <CardContent className={styles.cardContent}>
          <Typography gutterBottom variant='h5' component='h2'>
            {item.name}
          </Typography>
          <Typography>${item.price.toFixed(2)}</Typography>
          <Typography>
            {needed} of {item.quantity} needed.
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            variant='outlined'
            size='small'
            color='primary'
            href={item.url}
            target='_blank'
          >
            View
          </Button>
          {needed > 1 ? (
            <Button
              variant='contained'
              size='small'
              color='secondary'
              onClick={handleMenu}
              disabled={needed === 0}
            >
              {mutation.isLoading && <CircularProgress />}
              {needed > 0 ? "I'm buying this!" : 'Purchased'}
            </Button>
          ) : (
            <Button
              variant='contained'
              size='small'
              color='secondary'
              onClick={() => handleClick(1)}
              disabled={needed === 0}
            >
              {mutation.isLoading && <CircularProgress />}
              {needed > 0 ? "I'm buying this!" : 'Purchased'}
            </Button>
          )}
          <Menu
            id='simple-menu'
            anchorEl={anchorEl}
            keepMounted
            open={!!anchorEl}
            onClose={handleMenuClose}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
              },
            }}
          >
            {menuItems}
          </Menu>
        </CardActions>
      </Card>
    </Grid>
  );
};
