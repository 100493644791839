import React from 'react';
import { Card, CardContent, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles(() => ({
	card: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
	},
	cardMedia: {
		paddingTop: '56.25%', // 16:9
	},
	cardContent: {
		flexGrow: 1,
	},
}));

export const SkeletonCard = () => {
	const styles = useStyles();

	return (
		<Grid item xs={12} sm={6} md={3}>
			<Card className={styles.card} raised>
				<CardContent className={styles.cardContent}>
					<Skeleton animation='wave' variant='rect' height={200} />
					<Skeleton
						animation='wave'
						height={10}
						width='80%'
						style={{ marginBottom: 6 }}
					/>
					<Skeleton
						animation='wave'
						height={10}
						width='80%'
						style={{ marginBottom: 6 }}
					/>
				</CardContent>
			</Card>
		</Grid>
	);
};
