import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
	userId: null,
	token: null,
	triedAutoLogin: false,
};

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		login: (state, action) => {
			state.userId = action.payload.localId;
			state.token = action.payload.idToken;
		},
		isAutoLoggedIn: (state) => {
			state.triedAutoLogin = true;
		},
	},
});

export const { login, isAutoLoggedIn } = authSlice.actions;

export const authenticate = (values, isLogin) => {
	const { email, password, isRemembered } = values;
	let path = 'signUp';

	if (isLogin) {
		path = 'signInWithPassword';
	}

	return async (dispatch) => {
		const res = await axios.post(
			`https://identitytoolkit.googleapis.com/v1/accounts:${path}?key=AIzaSyAu_XRg3HSTel1shY042uyQq_VceeajJjY`,
			{
				email,
				password,
				returnSecureToken: true,
			},
			{
				validateStatus: (status) => {
					return status < 500; // Resolve only if the status code is less than 500
				},
			}
		);

		if (res.status !== 200) {
			const errorId = res.data.error.message;
			let message = 'Something went wrong!';

			switch (errorId) {
				case 'EMAIL_NOT_FOUND':
					message = 'This email could not be found.';
					break;
				case 'INVALID_PASSWORD':
					message = 'This password is invalid.';
					break;
				case 'USER_DISABLED':
					message = 'This account has been locked by the administrator.';
					break;
				case 'EMAIL_EXISTS':
					message =
						'This email address already exists, please log in or try a different address.';
					break;
				case 'TOO_MANY_ATTEMPTS_TRY_LATER':
					message = 'Too many attempts, please try again later.';
					break;
				case 'OPERATION_NOT_ALLOWED':
					message = 'Password sign-in is disabled for this project.';
					break;
				case 'INVALID_EMAIL':
					message = 'Not a proper email address format.';
					break;
				case 'WEAK_PASSWORD : Password should be at least 6 characters':
					message = 'Password should be at least 8 characters.';
					break;
				default:
					break;
			}
			throw new Error(message);
		}
		dispatch(login(res.data));
		if (isRemembered) {
			saveDataToStorage(res.data);
		}

		if (!isLogin) {
			const userData = {
				userId: res.data.localId,
				email,
				firstName: values.firstName,
				lastName: values.lastName,
			};
			try {
				await axios.post('https://api.coracopas.com/user', userData);
			} catch (err) {
				console.log(err);
			}
		}
	};
};

const saveDataToStorage = (data) => {
	window.localStorage.setItem(
		'authData',
		JSON.stringify({
			idToken: data.idToken,
			localId: data.localId,
		})
	);
};

export default authSlice.reducer;
