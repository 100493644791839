import React, { useEffect } from 'react';
import {
	BrowserRouter as Router,
	Switch,
	Redirect,
	Route,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { login, isAutoLoggedIn } from './redux/authSlice';

import LandingPage from './pages/LandingPage';
import Login from './pages/Login';
import Registry from './pages/Registry';
import SignUp from './pages/SignUp';
import { BabyShower } from './pages/BabyShower';

import { BottomNav } from './components/BottomNav';

const App = () => {
	const loggedIn = useSelector((state) => !!state.auth.token);
	const dispatch = useDispatch();

	const tryLogin = async () => {
		const authData = window.localStorage.getItem('authData');

		if (!authData) {
			dispatch(isAutoLoggedIn());
			return;
		}

		const data = JSON.parse(authData);
		dispatch(login(data));
	};

	useEffect(() => {
		tryLogin();
	}, [dispatch]);

	if (loggedIn) {
		return (
			<Router>
				<Switch>
					<Route exact path='/registry'>
						<Registry />
					</Route>
					<Route exact path='/'>
						<BabyShower />
					</Route>
					<Redirect to='/' />
				</Switch>
				<BottomNav />
			</Router>
		);
	}

	return (
		<Router>
			<Switch>
				<Route exact path='/'>
					<LandingPage />
				</Route>
				<Route path='/login'>
					<Login />
				</Route>
				<Route path='/signup'>
					<SignUp />
				</Route>
				<Redirect to='/' />
			</Switch>
		</Router>
	);
};

export default App;
