import React, { useState } from 'react';
import { BottomNavigation, BottomNavigationAction } from '@material-ui/core';
import {
	ChildFriendly,
	ChildFriendlyOutlined,
	PhotoCamera,
	PhotoCameraOutlined,
	PregnantWoman,
	PregnantWomanOutlined,
} from '@material-ui/icons';
import { Link } from 'react-router-dom';

export const BottomNav = () => {
	const registry = 'Registry';
	const shower = 'Baby Shower';
	const photo = 'Photos (Coming Soon)';

	const [value, setValue] = useState('/');

	const handleChange = (event, newValue) => {
		const anchor = document.getElementById(`${newValue}`);

		if (value === newValue && anchor) {
			anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
		}
		setValue(newValue);
	};

	const regBtn =
		value === '/registry' ? <ChildFriendly /> : <ChildFriendlyOutlined />;
	const showerBtn =
		value === '/' ? <PregnantWoman /> : <PregnantWomanOutlined />;
	const photoBtn =
		value === '/photos' ? <PhotoCamera /> : <PhotoCameraOutlined />;

	return (
		<BottomNavigation value={value} onChange={handleChange} showLabels>
			<BottomNavigationAction
				label={shower}
				value='/'
				icon={showerBtn}
				to='/'
				component={Link}
			/>
			<BottomNavigationAction
				label={registry}
				value='/registry'
				icon={regBtn}
				to='/registry'
				component={Link}
			/>
			<BottomNavigationAction
				label={photo}
				value='/photos'
				icon={photoBtn}
				to='/photos'
				component={Link}
				disabled
			/>
		</BottomNavigation>
	);
};
