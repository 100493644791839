import React from 'react';
import { Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		minHeight: '95vh',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundImage: 'url("/flower_bg.jpeg")',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
	},
	heroButtons: {
		marginTop: theme.spacing(4),
	},
}));

export const BabyShower = () => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Container maxWidth='sm'>
				<Typography
					component='h1'
					variant='h2'
					align='center'
					color='textPrimary'
					gutterBottom
				>
					Baby Shower Details
				</Typography>
				<Typography variant='h5' align='center' gutterBottom>
					Please join us for a Baby Shower honoring
				</Typography>
				<Typography align='center' gutterBottom component='h2' variant='h5'>
					Kelsey Copas
				</Typography>
				<Typography
					variant='h5'
					align='center'
					color='textSecondary'
					gutterBottom
				>
					Saturday, November 27, 2021
				</Typography>
				<Typography
					variant='h5'
					align='center'
					color='textSecondary'
					gutterBottom
				>
					1:00-4:00pm
				</Typography>
				<Typography
					variant='h5'
					align='center'
					color='textSecondary'
					gutterBottom
				>
					385 Allen Way Benicia, CA 94510
				</Typography>
				<Typography
					variant='h5'
					align='center'
					color='textSecondary'
					gutterBottom
				>
					RSVP to Rochelle Thurber at
				</Typography>
				<Typography
					variant='h5'
					align='center'
					color='textSecondary'
					gutterBottom
				>
					(707)567-0774 by November 13th
				</Typography>
				<Typography
					variant='h6'
					align='center'
					color='textSecondary'
					gutterBottom
				>
					This is a ladies only event, no kids, and no men.
				</Typography>
				<Typography
					variant='h6'
					align='center'
					color='textSecondary'
					gutterBottom
				>
					Thank you for understanding and respecting this!
				</Typography>
			</Container>
		</div>
	);
};
